import { Button } from '@material-tailwind/react'
import PaymentService from '../../Services/payment.services';
import React, { useEffect, useState } from "react";

export default function PlanCard(props:any) {

  return (
    <div className="container mx-auto px-1 col-span-1 shadow-2xl transform transition duration-500 hover:scale-110" onMouseOver={() => props.choosePricingOption(props.isAnnual)}>
              <div style={{ backgroundColor: 'white' }}>
                <div
                  className="relative flex flex-col flex-wrap items-center p-5 bg-opacity-75 "
                  style={{ cursor: 'auto' }}
                >
                  {/* <span className="absolute px-3 py-1 text-xs font-medium text-white bg-opacity-25 rounded-full bg-gradient-to-r from-pink-500 to-red-400 -top-3 ">
                    MOST POPULAR
                  </span> */}
                  <div>
                    <p className="text-4xl font-medium text-black my-7 text-center">{props.isAnnual?"Pay Annually" : "Pay Monthly"}</p>
                    <h1 className="text-6xl font-medium text-black my-7">
                      ${props.price}
                      <small className="pl-0 text-base align-baseline opacity-80">/{props.isAnnual?"year" : "month"}</small>
                    </h1>
                  </div>
                  <p className="pb-5 -mt-3 text-base text-grey-800 opacity-75">billed {props.isAnnual?"annually" : "monthly"}</p>
                  <ul className="w-full text-lg text-black-200 text-opacity-70">
                    <li className="flex items-center py-2">
                      <div className="flex items-center justify-center w-6 h-6 mr-3 bg-green-800 rounded-full">
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="1.0">
                            <path
                              d="M10.4923 1.14517C10.6873 0.951849 10.9506 0.843047 11.2252 0.842289C11.4998 0.841531 11.7637 0.948879 11.9598 1.14112C12.1558 1.33337 12.2684 1.59506 12.2731 1.86963C12.2778 2.1442 12.1742 2.40958 11.9848 2.60839L6.42174 9.56218C6.32613 9.66516 6.21073 9.7478 6.08245 9.80517C5.95417 9.86253 5.81564 9.89344 5.67515 9.89604C5.53465 9.89864 5.39507 9.87288 5.26475 9.82031C5.13444 9.76773 5.01606 9.68942 4.91671 9.59005L1.23078 5.90273C1.1281 5.80705 1.04574 5.69166 0.988612 5.56346C0.931488 5.43525 0.900771 5.29686 0.898295 5.15652C0.895819 5.01619 0.921634 4.87679 0.9742 4.74665C1.02677 4.61651 1.10501 4.49829 1.20425 4.39905C1.3035 4.2998 1.42172 4.22156 1.55186 4.16899C1.682 4.11643 1.82139 4.09061 1.96173 4.09309C2.10206 4.09556 2.24046 4.12628 2.36867 4.1834C2.49687 4.24053 2.61226 4.32289 2.70794 4.42558L5.62602 7.34226L10.4658 1.17583C10.4744 1.16504 10.4837 1.1548 10.4937 1.14517H10.4923Z"
                              fill="#FFFFFF"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      24/7 Customer support
                    </li>
                    <li className="flex items-center py-2">
                      <div className="flex items-center justify-center w-6 h-6 mr-3 bg-green-800 rounded-full">
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          { <g opacity="1.0">
                            <path
                              d="M10.4923 1.14517C10.6873 0.951849 10.9506 0.843047 11.2252 0.842289C11.4998 0.841531 11.7637 0.948879 11.9598 1.14112C12.1558 1.33337 12.2684 1.59506 12.2731 1.86963C12.2778 2.1442 12.1742 2.40958 11.9848 2.60839L6.42174 9.56218C6.32613 9.66516 6.21073 9.7478 6.08245 9.80517C5.95417 9.86253 5.81564 9.89344 5.67515 9.89604C5.53465 9.89864 5.39507 9.87288 5.26475 9.82031C5.13444 9.76773 5.01606 9.68942 4.91671 9.59005L1.23078 5.90273C1.1281 5.80705 1.04574 5.69166 0.988612 5.56346C0.931488 5.43525 0.900771 5.29686 0.898295 5.15652C0.895819 5.01619 0.921634 4.87679 0.9742 4.74665C1.02677 4.61651 1.10501 4.49829 1.20425 4.39905C1.3035 4.2998 1.42172 4.22156 1.55186 4.16899C1.682 4.11643 1.82139 4.09061 1.96173 4.09309C2.10206 4.09556 2.24046 4.12628 2.36867 4.1834C2.49687 4.24053 2.61226 4.32289 2.70794 4.42558L5.62602 7.34226L10.4658 1.17583C10.4744 1.16504 10.4837 1.1548 10.4937 1.14517H10.4923Z"
                              fill="#FFFFFF"
                            ></path>
                          </g> }
                        </svg>
                      </div>
                      Automatic insurance renewal
                    </li>
                    <li className="flex items-center py-2">
                      <div className="flex items-center justify-center w-6 h-6 mr-3 bg-green-800 rounded-full">
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          { <g opacity="1.0">
                            <path
                              d="M10.4923 1.14517C10.6873 0.951849 10.9506 0.843047 11.2252 0.842289C11.4998 0.841531 11.7637 0.948879 11.9598 1.14112C12.1558 1.33337 12.2684 1.59506 12.2731 1.86963C12.2778 2.1442 12.1742 2.40958 11.9848 2.60839L6.42174 9.56218C6.32613 9.66516 6.21073 9.7478 6.08245 9.80517C5.95417 9.86253 5.81564 9.89344 5.67515 9.89604C5.53465 9.89864 5.39507 9.87288 5.26475 9.82031C5.13444 9.76773 5.01606 9.68942 4.91671 9.59005L1.23078 5.90273C1.1281 5.80705 1.04574 5.69166 0.988612 5.56346C0.931488 5.43525 0.900771 5.29686 0.898295 5.15652C0.895819 5.01619 0.921634 4.87679 0.9742 4.74665C1.02677 4.61651 1.10501 4.49829 1.20425 4.39905C1.3035 4.2998 1.42172 4.22156 1.55186 4.16899C1.682 4.11643 1.82139 4.09061 1.96173 4.09309C2.10206 4.09556 2.24046 4.12628 2.36867 4.1834C2.49687 4.24053 2.61226 4.32289 2.70794 4.42558L5.62602 7.34226L10.4658 1.17583C10.4744 1.16504 10.4837 1.1548 10.4937 1.14517H10.4923Z"
                              fill="#FFFFFF"
                            ></path>
                          </g> }
                        </svg>
                      </div>
                      Legal documents included
                    </li>
                  </ul>

                  <p className="text-black-200 text-opacity-60">
                    Get the essentials to start with the magic of automated credentialing
                  </p>
                  {/* <a
                    className="flex items-center justify-center w-full py-3 mt-5 space-x-2 text-center btn-secondary umami--click--pricing-pro-action"
                    data-theme="none"
                    href="/#!"
                  >
                    <span>Signup Now</span>
                  </a> */}
                  <Button className="flex items-center  justify-center w-full py-3 mt-5 space-x-2 text-center ipayfield" data-ipayname="submit">
                    SELECT THIS PLAN
                  </Button>

                  <p className="pt-3 text-sm text-center text-indigo-200 text-opacity-60">
                    You'll be charged ${props.price} for your first {props.isAnnual?"year" : "month"}
                    <br /> 
                  </p>
                </div>
              </div>
            </div>
  );
}
