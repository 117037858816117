import { Button, Card, CardBody, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react"
import insuranceServices from '../../../Services/insurance.services';
import CreateInsuranceRequestPopup from "./CreateInsuranceRequest";
import pic from "../../images/dentalImg.png";
import { useNavigate } from "react-router-dom";
import fileServices from '../../../Services/file.service';


const InsuranceRequestView = (props: any) => {
  const API_URL = process.env.REACT_APP_API_URL; 

  const [insuranceRequest, setInsuranceRequest] = useState([{
    'credentialRequestId': 0,
    'name': '',
    'message': '',
    'requestDate': '',
    'effectiveDate':'',
    'credStatus': 0,
    'insuranceStatus': '',
    'signedPdfAvailable':false,
    'signedPdfVaultId':0
  }]);

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    getCredentialDashboard('all')
  }, [])

  

  const getCredentialDashboard = async (value: any) => {
    try {
      const result = await insuranceServices.GetDentistInsuranceRequest()

      if (value == 'all') {
        result.requestsAll.map((item: any) => {
          item.requestDate = moment(item.requestDate, "YYYYMMDD").format('MMM DD,YYYY')
          item.effectiveDate = item.effectiveDate == null ? '' : moment(item.effectiveDate, "YYYYMMDD").format('MMM DD,YYYY')
        })
        var resultAll = result.requestsAll;
        setInsuranceRequest(resultAll)
      }
      else if (value == 'inprogress') {
        result.requestsByStatus.InProgress.map((item: any) => {
          item.requestDate = moment(item.requestDate, "YYYYMMDD").format('MMM DD,YYYY')
        })
        var inProgress = result.requestsByStatus.InProgress;
        setInsuranceRequest(inProgress)
      }
      else if (value == 'readyToSend') {
        result.requestsByStatus.ReadyToSend.map((item: any) => {
          item.requestDate = moment(item.requestDate, "YYYYMMDD").format('MMM DD,YYYY')
        })
        var readyToSend = result.requestsByStatus.ReadyToSend;
        setInsuranceRequest(readyToSend)
      }
      else if (value == 'sent') {
        result.requestsByStatus.Sent.map((item: any) => {
          item.requestDate = moment(item.requestDate, "YYYYMMDD").format('MMM DD,YYYY')
        })
        var sent = result.requestsByStatus.Sent;
        setInsuranceRequest(sent)
      }
      else if (value == 'changesRequested') {
        result.requestsByStatus.ChangesRequested.map((item: any) => {
          item.requestDate = moment(item.requestDate, "YYYYMMDD").format('MMM DD,YYYY')
        })
        var changesRequested = result.requestsByStatus.ChangesRequested;
        setInsuranceRequest(changesRequested)
      }
      else if (value == 'insuranceCompanyFeedback') {
        result.requestsByStatus.InsuranceCompanyFeedback.map((item: any) => {
          item.requestDate = moment(item.requestDate, "YYYYMMDD").format('MMM DD,YYYY')
        })
        var insuranceCompanyFeedback = result.requestsByStatus.InsuranceCompanyFeedback;
        setInsuranceRequest(insuranceCompanyFeedback)
      }
      else if (value == 'finalApproval') {
        result.requestsByStatus.FinalApproval.map((item: any) => {
          item.requestDate = moment(item.requestDate, "YYYYMMDD").format('MMM DD,YYYY')
        })
        var finalApproval = result.requestsByStatus.FinalApproval;
        setInsuranceRequest(finalApproval)
      }
      else if (value == 'credentialComplete') {
        result.requestsByStatus.CredentialComplete.map((item: any) => {
          item.requestDate = moment(item.requestDate, "YYYYMMDD").format('MMM DD,YYYY')
          item.effectiveDate = moment(item.effectiveDate, "YYYYMMDD").format('MMM DD,YYYY')
        })
        var credentialComplete = result.requestsByStatus.CredentialComplete;
        setInsuranceRequest(credentialComplete)
      }

    } catch (error) {
      throw error
    }
  }

  const visbility = "";
  const stateManager = () => {
    setIsOpen(false)
  }
  const toggleState = () => {
    setIsOpen(true)
  }

  const moveToQuestionaries = (data: any) => {
    navigate('/createInsurance', { state: { data, isAdmin: false } })
  }
  return (
    <div>
      <div className="mx-auto px-4 sm:px-8 flex-grow">
        <div className="py-5">
          <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
            <h1 className="text-2xl leading-tight text-3xl">
              {props.heading}
            </h1>
            <div className={`text-end ${visbility}`} >
              {/* <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
                <Button className="flex items-center" onClick={toggleState} >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  {props.buttonName}
                </Button>
              </form> */}
            </div>
          </div>
          <p>{props.subTitle}</p>
        </div>
      </div>
      <Tabs value="html">
        <TabsHeader className="z-10">
          {props.data?.map(({ label, value }: any) => (
            <Tab key={value} value={value} onClick={() => { getCredentialDashboard(value) }}>
              {`${label}`}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="grid-cols-3 grid gap-2 ">
          {insuranceRequest.map((request, index) => (
            <>
              
                <Card className="h-64 m-2 " >
                  <CardBody className="p-1">
                    <div className="py-4 flex flex-row justify-between">
                      <div className="flex items-center">
                        <img className="w-18 h-12" src={pic} alt="logo" />
                        <div className="flex flex-col">
                        <button type="button" onClick={() => { moveToQuestionaries(request) }}>
                          <p className="text-[#222222]">{request.name}</p>
                          <p className="text-[#5f635f]">Started Date: {request.requestDate}</p>
                          {request.effectiveDate!='' && <p className="text-[#5f635f]">Effective Date: {request.effectiveDate}</p>}
                          </button>
                        </div>
                      </div>
                      <div className={`text-primary-50 p-3 rounded-2xl h-12 text-sm  ${request.insuranceStatus === "ReadyToSend" ? 'bg-yellow-500' : request.insuranceStatus === "Sent" ? 'bg-red-500' : request.insuranceStatus === "In Progress" ? 'bg-green-500' : request.insuranceStatus === "Changes Requested" ? 'bg-cyan-500' : request.insuranceStatus === "FinalApproval" ? 'bg-purple-500' : request.insuranceStatus === "Credential Complete" ? 'bg-teal-500' : 'bg-lime-500'}`}>{request.insuranceStatus}</div>
                    </div>

                    <div className={`p-3 min-h-[84px] ${request.insuranceStatus === "ReadyToSend" ? 'bg-yellow-50' : request.insuranceStatus === "Sent" ? 'bg-red-50' : request.insuranceStatus === "In Progress" ? 'bg-green-50' : request.insuranceStatus === "Changes Requested" ? 'bg-cyan-50' : request.insuranceStatus === "FinalApproval" ? 'bg-purple-50' : request.insuranceStatus === "Credential Complete" ? 'bg-teal-50' : 'bg-lime-50'}`}>
                      <p className="text-sm text-[#5f635f]">{request.message}</p>
                    </div>
                    {request.signedPdfAvailable && <Button onClick={() => fileServices.getFile(request.signedPdfVaultId)}>Download</Button>}
                    
                    {/* <div className="px-3 py-4">
                    <span className="uppercase text-sm text-[#5191f1]">download</span>
                    </div> */}
                  </CardBody>
                </Card>
              
              

            </>

          ))}
        </TabsBody>
        {/* <CreateInsuranceRequestPopup
          isOpen={isOpen}
          stateManager={stateManager}
          heading={"Create new Insurance Request"}
          description={"You are about to create a new insurance request, after selecting your state we will ask for the neccessory information in a few simple steps."}
          buttonName={"Create Insurance Request"}
          requireName={true}
        /> */}
      </Tabs>
    </div>
  )
}

export default InsuranceRequestView