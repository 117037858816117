import React, { useEffect, useState } from "react";
import PlanCard from "./planCard";
import PaymentPage from "./PaymentPage";
import CredentialService from '../../Services/credential.services';
import TokenService from "../../Services/token.service";
import IntelliPayForm from "../user/insurances/payment/intelliPayForm";

export default function PriceOptions(props:any) {
    const [dataLoaded, setDataLoaded]=useState(false);
    const [annualPrice, setAnnualPrice]=useState(0.0);
    const [monthlyPrice, setMonthlyPrice]=useState(0.0);
    const [collectPayment, setCollectPayment]=useState(false);
    const [paymentAmount, setPaymentAmount]=useState(0.0);
    const [paymentTerm, setPaymentTerm]=useState("");
    const [userEmail, setUserEmail]=useState("");
    const [userId, setUserId]=useState("");
    const [isPaymentAnnual, setIsPaymentAnnual]=useState("false");

    useEffect(()=>{
        GetPricingOptions();
        setUserEmail(TokenService.getUser().user.email);
        setUserId(TokenService.getUser().user.id);
    },[]); 



const GetPricingOptions = async()=>{
    setDataLoaded(false);
    var response= await CredentialService.GetPricing()
    if(response.success){
        setAnnualPrice(response.data.annualPrice);
        setMonthlyPrice(response.data.monthlyPrice);
        setDataLoaded(true);
        console.log(response);
    }   
}

const choosePricingOption = (isAnnual:boolean) => {
    
    //I know this looks ghetto.  For some reason it wouldn't propagate if the property was a boolean so I made it a string
    setIsPaymentAnnual(isAnnual?"true":"false");
    if (isAnnual) {
        setPaymentAmount(annualPrice);
        setPaymentTerm("yearly");
    }
    else {
        setPaymentAmount(monthlyPrice);
        setPaymentTerm("monthly");

    }
    setCollectPayment(true);
}

  return (
    <div>
        
        {dataLoaded? 
            <div className="">
                <div className="">
                    <h1 className="font-medium leading-tight text-4xl mt-0 mb-12 text-black-600 text-center">
                    You are just one step away!
                    </h1>
                    <div className="flex justify-center grid grid-cols-2 gap-4">
                        <PlanCard price={annualPrice} isAnnual={true} choosePricingOption={choosePricingOption}></PlanCard><PlanCard price={monthlyPrice} isAnnual={false} choosePricingOption={choosePricingOption}></PlanCard>
                    </div>
                </div>
                {collectPayment && <PaymentPage paymentAmount={paymentAmount} collectPayment={collectPayment} userEmail={userEmail} userId={userId} monthlyPrice={monthlyPrice} annualPrice={annualPrice}/>}
            </div> 
            :
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        }   
    </div>
  );
}


