import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import MobileAppBar from "./components/mobileAppBar";
import { AuthLink } from "./interfaces";
import { adminNavigation, userMenu, userNavigation } from "./menus";
import MobileMenuButtom from "./components/mobileMenuButtom";
import useAuth from "../../hooks/useAuth";
import LoggedSection from "./components/loggedSection";
import LogInAppBar from "./components/logInAppBar";
import { userIsAdmin } from "../../models/user";
import CredentalLogo from "../../icons/credentalLogo";
import { useNavigate } from 'react-router-dom'
import { wrap } from "module";
 
export default function SideBar() {
  const { user,signout } = useAuth();
  const [menu, setMenu] = useState<AuthLink[]>([]);
  const navigate = useNavigate(); 
  const [activeMenu, setActiveMenu] = useState<AuthLink>();
  
const logOut = async () => {
    await signout()
    window.localStorage.clear();
    navigate('/login')
  }
 
  useEffect(() => {
    if (userIsAdmin(user)) {
      setMenu([...adminNavigation]); 
      setActiveMenu([...adminNavigation][0]);
    } else {
      setMenu([...userNavigation]); 
      setActiveMenu([...userNavigation][0]);
    }
    console.log(activeMenu);
   
  }, [user]); 
 
  return (
    <div className="bg-white min-h-screen w-56 text-black flex-shrink-0 border-r border-solid border-grey-200">
      {/* Logo */}
      <div className="mb-2 px-7 py-2 border-b border-grey-200 h-16 flex items-center">
        <CredentalLogo  width={150}/>
      </div>
      {/* Navigation */}
      <div className="px-3 py-4 flex flex-col">
        {/* Item */}
        {menu.map((item, i) => (
          <Link to={item.href} key={i}>
             
            <div id={'div_'+i} 
              // style ={{ whiteSpace: 'nowrap'}}
               onClick={() => setActiveMenu(item)}
               className={`flex text-base items-center py-2 px-4 rounded mb-1 cursor-pointer transition-all ease-out duration-200
               hover:text-primary-500  ${(activeMenu == item ) && "bg-primary-50 text-primary-500 font-bold"}`} >
             <div className="w-5 mr-3">{item.icon}</div>
             {(item.name=='Log out'?<p className="font-regular" onClick={logOut}>{item.name}</p>: <p className="font-regular" >{item.name}</p>) }
            </div>
            
          </Link>
        ))}
      </div>
    </div>
  );
}
