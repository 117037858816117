import apiClient from "./api";
class StateService{
 
      async GetStates() { 
        try {
          const response = await apiClient.get("/state/list"); 
          return response.data;
          
        } catch (err) {
            console.log(err); 
        }
      }
}
export default new StateService();