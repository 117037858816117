import { Dialog, Transition } from '@headlessui/react';
import { Button, Input, Select, Option } from '@material-tailwind/react';
import React, { useEffect, useState, Fragment, } from 'react';
import { AtSymbolIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { H3, P, Link } from "../../../components/typography/typography";
import { useNavigate } from "react-router-dom";
import statesService from '../../../Services/states.service';
import insuranceServices from '../../../Services/insurance.services';
import Multiselect from 'multiselect-react-dropdown';
import { any } from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import credentialService from '../../../Services/credential.services';



const formValues = {
  insuranceId: '',
  stateID: '',
}

const addInsuranceRequestSchema = Yup.object({
  insuranceId: Yup.array().required('Please select a insurance')
})

const multiselectStyle = {
  multiselectContainer: {
    width: '500px', // Set the desired width
  },
  searchBox: {
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  chips: {
    background: '#2196f3',
    color: '#fff',
  },
  optionContainer: {
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  option: {
    background: '#fff',
    color: '#333',
    hoverBackground: 'green',
    hoverColor: '#333',
  },
};

const SelectInsurance = (props: any) => {

  const [errorMessage, setErrorMessage] = useState('');
  const [insuranceDll, setInsuaranceDll] = useState([
    {
      id: 0,
      name: '',
      phone: '',
      mapFile: ''
    }
  ]);
  const [selectedInsurance, setSelectedInsurance] = useState();
  const navigate = useNavigate();



  useEffect(() => {
    let stateJson = localStorage.getItem('insuranceRequesteState');
    if (stateJson != null)
    {
        let state = JSON.parse(stateJson);
        bindInsuranceOnState(state.state.id);
    }
    else
    {
         retrieveInsurance();   
    }


  }, [])

  async function retrieveInsurance()
  {
    let state = await credentialService.GetSelectedState();    
    bindInsuranceOnState(state.data.id);
  }


  const { values, errors, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: formValues,
    // validationSchema: addInsuranceRequestSchema,
    onSubmit: (values) => {
        SubmitInsurance();
    },
  })


  const bindInsuranceOnState = async (stateId: any) => {
    const dllInsurance = await insuranceServices.GetInsuranceOnState(stateId);
    setInsuaranceDll(dllInsurance);
  }


  const insuranceSelectHandle = (e: any) => {
    setSelectedInsurance(e);
  }

  const insuranceRemoveHandle = (e: any) => {
    setSelectedInsurance(e);
  }

  const SubmitInsurance = async () => {

    try {

      const resultInsurances = await insuranceServices.PostInsuranceRequestInsurance(selectedInsurance)
      if (resultInsurances != null) {
        toast("Insurance company added successfully!", { position: "top-center" });
      }
      //setInsuranceRequest(resultInsurances)
      localStorage.setItem('insuranceRequesteInsurance', JSON.stringify(resultInsurances))
      localStorage.setItem('currentStep', '')
      let url = await credentialService.GetNextPage();
      navigate("/"+url, { replace: true });
    } catch (error) {
      console.log(error)
      throw error
    }
  }



  const { heading, description, buttonName } = props
  return (
    <div>
          <div className="items-center justify-center">
          
            <div className="flex items-center justify-center p-4 text-center">
                <form className="mt-6" onSubmit={handleSubmit}>
                      <h1 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black-600">{heading}</h1>
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Please select the companies you would like to credential with.
                        </p>

                        <div className="mb-4">
                          <div className="">
                            <Multiselect
                              options={insuranceDll}
                              onSelect={insuranceSelectHandle}
                              onRemove={insuranceRemoveHandle}
                              displayValue="name"
                              placeholder='Select...'
                              style={multiselectStyle}
                            >
                            </Multiselect>
                          </div>
                        </div>

                      </div>
                      {/*footer*/}

                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <div className="modal-footer">
                        <Button
                          className="flex items-center"
                          type='submit'

                        >
                          {buttonName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                          </svg>
                        </Button>

                      </div>
                    </div>
                </form>
            </div>
          </div>
    </div>
  )
}
export default SelectInsurance