import apiClient from './api'
class InsuranceService {
  async CreateInsuranceCompany(postData: any) {
    console.log(postData)
    try {
      const response = await apiClient.post('/InsuranceCompany', postData)
      console.log(response)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  async GetAllInsuranceCompanies() {
    try {
      const response = await apiClient.get(`/InsuranceCompany/all`)
      return  response.data;
    } catch (err) {
      return { success: false }
      console.log(err)
    }
  }

  async GetInsuranceOnState(id: any) {
    try {
      const response = await apiClient.get(`State/${id}/insurance-companies`);
      return response.data;

    } catch (err) {
      console.log(err);
    }
  }

  async GetDentistInsuranceRequest() {
    try {
      const response = await apiClient.get("/Credential/dashboard");
      return response.data;

    } catch (err) {
      console.log(err);
    }
  }

  async GetDentistInsuranceByState(id: any) {
    try {
      const response = await apiClient.get(`/State/${id}/insurance-companies`);
      return response.data;

    } catch (err) {
      console.log(err);
    }
  }

  async PostInsuranceRequestState(postData: any) {
    console.log(postData)
    try {
      const response = await apiClient.post('/Credential/State', postData)
      console.log(response)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async PostInsuranceRequestInsurance(postData: any) {
    console.log(postData)
    try {
      const response = await apiClient.post('/Credential/Insurance-Companies', postData)
      console.log(response)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  async GetAdminInsuranceRequest() {
    try {
      const response = await apiClient.get("/Admin/dashboard");
      return response.data;

    } catch (err) {
      console.log(err);
    }
  }

  async DeleteInsuranceCompeny(InsuranceCompanyId: any) {
    console.log(InsuranceCompanyId);
    try {
      const response = await apiClient.delete(`/InsuranceCompany/${InsuranceCompanyId}`);
      return response.data;

    } catch (err) {
      console.log(err);
    }
  }

  async PutInsuranceCompany(InsuranceCompanyId: any, postData: any) {
    console.log(postData)
    try {
      const response = await apiClient.put(`InsuranceCompany/${InsuranceCompanyId}`, postData)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
}

export default new InsuranceService()